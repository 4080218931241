//
// Textual form controls
//

.form-control {
  appearance: none;
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius-lg;
  transition: $input-transition;
  box-shadow: none;

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }

  // Disabled and read-only inputs
  &:disabled,
  &[readonly] {
    opacity: 1;
  }

  &:disabled{
    background-color: $input-disabled-bg;
    cursor: not-allowed;
  }
}

textarea.form-control{
  height: auto;
}

select.form-control {
  &:not([size]):not([multiple]) {
    appearance: none;
    @if $text-direction == ltr {
      padding-right: ($input-padding-x * 3);
      //background-position: right ($input-padding-x/2) center;
    } @else {
      padding-left: ($input-padding-x * 3);
      //background-position: left ($input-padding-x/2) center;
    }
    //background-color: $input-bg;
    //background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScyNCcgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0Jz48cGF0aCBmaWxsPScjNDQ0NDQ0JyBkPSdNNy40MSA3Ljg0TDEyIDEyLjQybDQuNTktNC41OEwxOCA5LjI1bC02IDYtNi02eicvPjwvc3ZnPg==);
    //background-repeat: no-repeat;
    //background-size: 24px 24px;

    &:disabled{
      background-color: $input-disabled-bg;
    }
  }

  &:focus::-ms-value {
    color: $input-color;
    background-color: $input-bg;
  }
}


// 
// Form control sizing
//

.form-control-lg {
  height: $input-height-lg;
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
}

// 
// Form groups
//

.form-group {
  margin-bottom: $form-group-margin-bottom;
}


//
// Labels
//

label{
  // font-size: $font-size-sm;
  display: inline-block;
  line-height: $line-height-computed;
}

// 
// Floating labels
//

.floating-label{
  position: relative;
  margin-bottom: $form-group-margin-bottom;

  > span{
    position: absolute;
    top: 17px;
    bottom: auto;
    width: $input-icon-width;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $input-placeholder-color !important;

    @if $text-direction == ltr {
      right: 0;
    } @else {
      left: 0;
    }
  }

  .form-control{
    &:focus{
      + label{
        color: $input-focus-border-color;
      }
    }
  }

  label{
    display: block;
    font-weight: normal;
    color: $input-placeholder-color;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    padding: ($input-padding-y + 1) ($input-padding-x + 1) 0;
    pointer-events: none;
    user-select: none;
    transition: all 200ms;
    @include text-truncate();
  }
  
  &.floating-label-show{
    .form-control{
      padding-top: theme-spaces(3);
      padding-bottom: theme-spaces(1);
    }

    label{
      opacity: 1;
      visibility: visible;
      font-size: $font-size-sm - 1;
      transform: translate3d(0, -($input-padding-y + 1), 0);
    }
  } 
}


// 
// Form validation
// 

@include form-validation-state(error, $danger);


// 
// Input addon
//

.input-icon {
  position: relative;

   > span{
    position: absolute;
    top: 0;
    bottom: 0;
    width: $input-icon-width;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $input-placeholder-color;
  }

  .form-control{
    &:focus + span{
      color: $input-focus-border-color;
    }
  }
}

.input-icon:not(.input-icon-flip){
  .form-control{
    @if $text-direction == ltr {
      padding-left: $input-icon-width;
    } @else {
      padding-right: $input-icon-width;
    }
  }
  > span{
    @if $text-direction == ltr {
      left: 0;
    } @else {
      right: 0;
    }
  }
  &.floating-label{
    label{
      @if $text-direction == ltr {
        padding-left: $input-icon-width;
      } @else {
        padding-right: $input-icon-width;
      }
    }
  }
}

.input-icon-flip{
  .form-control{
    @if $text-direction == ltr {
      padding-right: $input-icon-width;
    } @else {
      padding-left: $input-icon-width;
    }
  }

  > span{
    @if $text-direction == ltr {
      right: 0;
    } @else {
      left: 0;
    }
  }

  &.floating-label{
    label{
      @if $text-direction == ltr {
        padding-right: $input-icon-width;
      } @else {
        padding-left: $input-icon-width;
      }
    }
  }
}

// Form Icon React

.form-icon-container{
  position: relative;
}

.form-icon{
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: $input-icon-width;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $input-placeholder-color;

  @if $text-direction == ltr {
    left: 0;
    right: auto;
  } @else {
    right: 0;
    left: auto;
  }

  &:not(.form-icon-flip) ~ .form-control{
    @if $text-direction == ltr {
      padding-left: $input-icon-width;
    } @else {
      padding-right: $input-icon-width;
    }
  }
}

.form-icon.form-icon-flip{
  @if $text-direction == ltr {
    right: 0;
    left: auto;
  } @else {
    left: 0;
    right: auto;
  }

  ~ .form-control{
    @if $text-direction == ltr {
      padding-right: $input-icon-width;
    } @else {
      padding-left: $input-icon-width;
    }
  }
}

.floating-label .form-icon-container + label{
  @if $text-direction == ltr {
    padding-left: $input-icon-width;
  } @else {
    padding-right: $input-icon-width;
  }
}

//
// Bubble Error Message
//

.searchMask-error{
  position: absolute;
  bottom: 100%;
  max-width: 200px;
  padding: theme-spaces(1) theme-spaces(2);
  background-color: $danger;
  font-size: $font-size-sm;
  text-align: center;
  color: $white;
  opacity: .9;
  margin-bottom: theme-spaces(3);
  border-radius: $border-radius;
  @include animate(slide-top, 0.35s, ease-out);
  z-index: 5;

  &:after{
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    @include caret($size:6px, $color: $danger);
  }
}

//
// Ferries Passengers Allocating
//

.allocation-price {
  font-size: 25px;
  min-width: 93px;
}

.allocation-discount {
  margin-top: -11px;
}

//
// Ferries Payment
//

.expiration-date {
  color: $input-color;
  background-color: $input-bg;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;

  .form-control {
    height: calc(#{$input-height} - 2px);
  }
}

.has-error .expiration-date {
  border-color: theme-color('danger');
}

.disabled .expiration-date {
  background: $input-disabled-bg;
  cursor: not-allowed;
}

// Last pass icon
input {
  ~ div[data-lastpass-icon-root] {
    display: none;
  }

  &.lastpass {
    ~ div[data-lastpass-icon-root] {
      display: block;
    }
  }
}
