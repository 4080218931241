
// Kill the scroll on the body
.modal-open, .ReactModal__Body--open {
  overflow: hidden;
}

.modal-open {
  .ReactModal__Overlay {
    transform: translateY(100%);
    transition: all 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    transform: translateY(0px);
  }

  .ReactModal__Overlay--before-close {
    transform: translateY(100%);
  }
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  overflow: hidden;
  outline: 0;

  .modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: $modal-dialog-margin;
  pointer-events: none;

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    transition: $modal-transition;
    transform: translate(0, -25%);
  }
  .modal.in & {
    transform: translate(0, 0);
  }
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  border-radius: $border-radius-lg;
  box-shadow: $modal-content-box-shadow-xs;
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.in { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
  @include border-top-radius($border-radius-lg);

  .close {
    padding: $modal-header-padding;
    @if $text-direction == ltr {
      margin: (-$modal-header-padding) (-$modal-header-padding) (-$modal-header-padding) auto;
    } @else {
      margin: (-$modal-header-padding) auto (-$modal-header-padding) (-$modal-header-padding);
    }
  }
}

// Title text within header
.modal-title {
  margin-bottom: 0;
  line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: $modal-inner-padding;
}

// Footer (for actions)
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: calc(theme-spaces(4) / 2) $modal-inner-padding;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;

  // Easily place margin between footer elements
  @if $text-direction == ltr {
    > :not(:first-child) { margin-left: theme-spaces(1); }
    > :not(:last-child) { margin-right: theme-spaces(1); }
  } @else {
    > :not(:first-child) { margin-right: theme-spaces(1); }
    > :not(:last-child) { margin-left: theme-spaces(1); }
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-content {
    box-shadow: $modal-content-box-shadow-sm-up;
  }

  .modal-sm { max-width: $modal-sm; }
}

@include media-breakpoint-up(lg) {
  .modal-lg { max-width: $modal-lg; }
}

#authenticationModal {
  .modal-dialog {
    max-width: 800px;
  }
}

.modal-shadow-bottom {
  box-shadow: 0 0.4rem 0.5rem rgba(0, 0, 0, 0.10);
}
